import { createWebHistory, createRouter } from 'vue-router'
// import store from '../store/index'
import middlewarePipeline from './middlewarePipline'

// Routes
// import auth from "./routes/auth"
// import misc from "./routes/misc"
import {generateRoutes} from "./routes/user"
// import admin from "./routes/admin"
const routes = [
    // ...auth,
    // ...misc,
    ...generateRoutes(),
    // ...admin,
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {
            top: 0
        }
    },
})

router.beforeEach((to, from, next) => {
    const middleware = to.meta.middleware
    //     const context = { to, from, next, store }
    const context = { to, from, next }

    if (!middleware) {
        return next()
    }
    // console.log('context',context)
    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    })
})

export default router
