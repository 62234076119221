
<template>
  <TransitionRoot
    as="template"
    :show="showLoading"
  >
    <Dialog
      as="div"
      class="fixed z-50 inset-0 overflow-y-auto"
    >
      <div class="flex justify-center align-middle min-h-screen  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <!-- Spinner -->
          <div
            v-if="typeOfLoadingScreen === 'spinner'"
            class="my-auto inline-block h-max max-w-5xl align-bottom  text-left  transform transition-all sm:align-middle px-2 pt-4 pb-8 "
          >
            <div class=" m-auto">
              <div class=" transform -translate-x-1/2 -translate-y-1/2 loader ease-linear rounded-full border-8 border-t-8 h-32 w-32 "></div>
            </div>
          </div>
          <!-- Input -->
          <div
            v-if="typeOfLoadingScreen === 'input'"
            class="inline-block h-max text-left  transform transition-all "
          >
            <div class="w-screen lg:w-full h-full items-center font-thin font-open_sans text-lg xl:text-fs_headline_div bg-white  p-6 lg:p-20 rounded-lg">
              <div class="flex flex-col justify-content-between">
                <div
                  v-if="serviceType==='delivery'"
                  class="lg:text-rd_fontsize-22  "
                >Please enter your ZIP Code</div>
                <div
                  v-else
                  class="lg:text-rd_fontsize-22  "
                >Please enter a JDE Number</div>
                <div class="w-full py-2 px-2 flex flex-col">
                  <div class="w-full  flex flex-row">
                    <input
                      type="text"
                      v-model="inputValue"
                      :placeholder="[serviceType==='delivery' ? 'ENTER ZIP' : 'ENTER JDE']"
                      class="text-rd_fontsize-8  md:text-rd_fontsize-12 block w-8/12 border hover:border-0 focus:ring-rd_red rounded-l-lg"
                    />
                    <div
                      @click.prevent="handleSubmit"
                      class="cursor-pointer block hover:bg-hover_red justify-center content-center  text-center  text-rd_fontsize-8  md:text-rd_fontsize-12 w-4/12 border border-rd_red text-white bg-rd_red py-3 rounded-r-lg"
                    >
                      Enter
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- Not Found -->
          <div
            v-if="typeOfLoadingScreen === 'not found'"
            class="inline-block h-max max-w-6xl align-bottom  text-left  transform transition-all sm:align-middle"
          >
            <div class="w-full h-full items-center font-thin font-open_sans text-lg xl:text-fs_headline_div bg-white  p-8 rounded-lg">
              <div class="text lg:text-rd_fontsize-32  ">
                <span v-if="serviceType ==='delivery'">Sorry, Home Delivery service is not supported in your area.</span>
                <span v-else>{{reservationsErrorMsg || 'Sorry, that is not a valid JDE Number'}}</span>
                <div class="my-4 flex flex-row gap-4">
                  <div
                    @click.prevent="toggleModal()"
                    class="flex justify-center content-center border-2 border-transparent bg-rd_red  w-full h-auto mx-auto md:w-[304px] outline-none ring-0 p-4 rounded-lg"
                  >
                    <span
                      v-if="serviceType ==='delivery'"
                      class="text-white lg:text-rd_fontsize-22  text-center  font-semibold lg:py-4"
                    >Try another ZIP code</span>
                    <span
                      v-else
                      class="text-white lg:text-rd_fontsize-22  text-center  font-semibold lg:py-4"
                    >Try another JDE Number</span>
                  </div>
                  <a
                    href="https://www.rugdoctor.com/"
                    class="flex justify-center content-center border-2 border-transparent bg-rd_red  w-full h-auto mx-auto md:w-[304px] outline-none ring-0 p-4 rounded-lg"
                  >
                    <span class="text-white lg:text-rd_fontsize-22  text-center  font-semibold py-4">Return</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
  //Purpose is to create an component that handles all the loading screens
  import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
  export default {
    components: {
      Dialog,
      DialogOverlay,
      DialogTitle,
      TransitionChild,
      TransitionRoot,
    },
    props: ['typeOfLoadingScreen', 'showLoading', 'showSpinner', 'showInputModal', 'toggleInputModal', 'changeZipcode', 'displaySpinner', 'serviceType', 'changeJDE', 'showNotFoundModal', "toggleNotFoundModal", 'serviceType', 'reservationsErrorMsg'],
    setup() {
      const inputValue = ref('')
      return {
        inputValue,
      }
    },
    methods: {
      handleSubmit() {
        if (this.serviceType === 'delivery') {
          this.changeZipcode(this.inputValue)
        }
        else {
          this.changeJDE(this.inputValue)
        }
        this.toggleInputModal()
        this.displaySpinner(true)
      },
      toggleModal() {
        this.toggleNotFoundModal()
      }
    },


  }
</script>
