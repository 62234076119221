<template>
    <SpinnerModal
        v-if="showSpinner"
        :showSpinner="showSpinner"
        :timeOutFunc="displayNotFoundModal"
    />
    <InputModal
        v-if="showInputModal"
        :showInputModal="showInputModal"
        :toggleInputModal="toggleInputModal"
        :changeZipcode="changeZipcode"
        :changeJDE="changeJDE"
        :displaySpinner="displaySpinner"
        :serviceType="serviceType"
    />
    <NotFoundModal
        v-if="showNotFoundModal"
        :showNotFoundModal="showNotFoundModal"
        :toggleNotFoundModal="toggleNotFoundModal"
        :serviceType="serviceType"
        :reservationsErrorMsg="reservationsErrorMsg"
    />
    <div class='2xl:px-4 overflow-x-hidden overflow-y-auto'>
        <div class="flex p-6 w-full sm:fixed sm:bg-white sm:z-10">
            <a
                class="w-1/2 sm:max-w-xs object-contain sm:mb-0 mb-4"
                href="https://www.rugdoctor.com/"
                target="_blank"
            >
                <img
                    alt="Rug Doctor Logo"
                    class="object-contain sm:mb-0 mb-4"
                    src="https://www.rugdoctor.com/wp-content/uploads/logo.svg"
                />
            </a>
            <h2
                v-if="serviceType ==='delivery'"
                class="flex flex-col lg:flex-row w-1/4  sm:w-auto object-contain pl-6 text-rd_fontsize-22 font-bold sm:my-auto"
            ><span>Home</span><span class="hidden lg:block w-1"></span><span>Delivery</span></h2>
            <h2
                v-else
                class="flex flex-col lg:flex-row w-1/4  sm:w-auto object-contain pl-6 text-rd_fontsize-22 font-bold sm:my-auto"
            >Reservation</h2>
        </div>
        <!-- Tabs -->
        <div class='w-full sm:fixed sm:mt-32 z-10 sm:pr-6 sm:bg-white'>
            <div class="relative z-0  flex flex-col sm:flex-row ">
                <transition-group name="tab-list">
                    <div
                        v-for="(tab, index) in tabs"
                        :key="tab.name"
                        class="flex flex-col w-full sm:h-32 2xl:h-[150px] cursor-pointer"
                    >
                        <div
                            id="tabs"
                            @click='handleTabs(index)'
                            :class="[index === currentTabIndex ? ' bg-rd_red text-white h-[114px] ' : 'h-[68px] bg-rd_gray text-white hover:bg-hover_red', ' hover:cursor-pointer transform duration-700 ease-in-out  md:mx-2 relative min-w-0  text-center border-b md:border-b-0 sm:rounded-lg']"
                        >
                            <div class='flex flex-row justify-start items-center px text-left items-left h-full pl-8'>
                                <h1 :class="[index === currentTabIndex? 'text-rd_fontsize-56 font-bold ' :'text-rd_fontsize-28 ', 'flex mr-4 items-left text-left' ]">
                                    {{index+1}}
                                </h1>
                                <div :class="[index === currentTabIndex ? 'text-rd_fontsize-20  text-left  font-bold' :'text-rd_fontsize-18 ', 'flex  flex-col text-left ' ]">
                                    <span>
                                        {{ tab.name }}
                                    </span>
                                    <!--  Tabs Conditional Dates-->

                                    <span
                                        v-if="index===0 && selectionForm?.delivery?.day"
                                        class="text-rd_fontsize-14 "
                                    >
                                        {{ selectionForm?.delivery?.day?.dayName +","}}
                                        <span>{{ selectionForm?.delivery?.day?.monthName?.slice(0, 3) + " " +selectionForm?.delivery?.day?.dayNumber +" "}}</span>
                                        <span v-if="selectionForm?.delivery?.time">{{ selectionForm?.delivery?.time?.hours}}</span>
                                    </span>

                                    <span
                                        v-else-if="index===1 && selectionForm?.pickup?.day"
                                        class="text-rd_fontsize-14"
                                    >
                                        {{ selectionForm?.pickup?.day?.dayName +","}}
                                        <span>{{ selectionForm?.pickup?.day?.monthName?.slice(0, 3) + " " +selectionForm?.pickup?.day?.dayNumber +" "}}</span>
                                        <span v-if="selectionForm?.pickup?.time">{{ selectionForm?.pickup?.time?.hours}}</span>
                                    </span>

                                    <span
                                        class="overflow-hidden flex flex-row  text-rd_fontsize-14 "
                                        v-else-if="tab.name ==='Choose a product' && selectionForm?.productSelected?.machine"
                                    >
                                        <span>{{selectionForm?.productSelected?.machine?.name}}</span>

                                    </span>
                                    <span
                                        class="overflow-hidden flex flex-row  text-rd_fontsize-14 "
                                        v-else-if="tab.name ==='Other Featured Items' && findSolution"
                                    >
                                        <span>{{firstSolution?.name}}</span>

                                    </span>
                                    <!-- Conditional Text End -->
                                </div>
                                <!-- Conditional image -->
                                <img
                                    v-if="tab.name ==='Choose a product' && selectionForm?.productSelected?.machine"
                                    :alt="selectionForm?.productSelected?.machine?.name"
                                    class="block  object-contain  h-[67px] w-[67px] sm:mb-0 overflow-hidden"
                                    :src="selectionForm?.productSelected?.machine?.photo?.content?.url || selectionForm?.productSelected?.machine?.photo?.url"
                                >
                                <div v-if="tab.name ==='Other Featured Items' && firstSolution?.photo">
                                    <img
                                        :alt="firstSolution?.name"
                                        class="block  object-contain  h-[67px] w-[67px] sm:mb-0 overflow-hidden"
                                        :src="firstSolution?.photo?.content?.url || firstSolution?.photo?.url"
                                    >
                                </div>

                            </div>
                        </div>
                        <!-- Mobile View -->
                        <div
                            v-if="mobileView === true && index === currentTabIndex"
                            class='inline-block sm:hidden w-full'
                        >
                            <RouterView
                                :selectionForm="selectionForm"
                                :datesArray="datesArray"
                                :pickupDatesArray="pickupDatesArray"
                                :calendarToggle="calendarToggle"
                                :timeOfDayOptions="timeOfDayOptions"
                                :pickupTimeOfDayOptions="pickupTimeOfDayOptions"
                                :machineList="machineList"
                                :bundleList="bundleList"
                                :toolList="toolList"
                                :solutionList="solutionList"
                                :cart="cart"
                                :productData="productData"
                                :dataRes="dataRes"
                                :customer="customer"
                                :confirmation="confirmation"
                                :productPageErrorMsg="productPageErrorMsg"
                                :checkoutPageErrorMsg="checkoutPageErrorMsg"
                                :currentIndex="currentIndex"
                                :currentLocation="currentLocation"
                                :couponErrorMsg="couponErrorMsg"
                                :displaySpinner="displaySpinner"
                                :nextTab="nextTab"
                                :setCurrentTab="setCurrentTab"
                                :tabs="tabs"
                                :serviceType="serviceType"
                                @handleTabs="handleTabs"
                                :dayLimit="dayLimit"
                                :kiosk_id="kiosk_id"
                                :mobileView="mobileView"
                                :affiliateId="affiliateId"
                                :updateOrderError="updateOrderError"
                                :holidays="holidays"
                                :timeslots="timeslots"
                                :handleConfirmationPage="handleConfirmationPage"
                            />
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
        <!-- Desktop View -->
        <div
            v-if="!mobileView"
            class='hidden sm:inline-block lg:py-6 lg:px-0 w-full mt-60'
        >
            <RouterView
                :selectionForm="selectionForm"
                :datesArray="datesArray"
                :pickupDatesArray="pickupDatesArray"
                :calendarToggle="calendarToggle"
                :timeOfDayOptions="timeOfDayOptions"
                :pickupTimeOfDayOptions="pickupTimeOfDayOptions"
                :machineList="machineList"
                :bundleList="bundleList"
                :toolList="toolList"
                :solutionList="solutionList"
                :cart="cart"
                :productData="productData"
                :dataRes="dataRes"
                :customer="customer"
                :confirmation="confirmation"
                :productPageErrorMsg="productPageErrorMsg"
                :checkoutPageErrorMsg="checkoutPageErrorMsg"
                :currentIndex="currentIndex"
                :currentLocation="currentLocation"
                :couponErrorMsg="couponErrorMsg"
                :displaySpinner="displaySpinner"
                :nextTab="nextTab"
                :setCurrentTab="setCurrentTab"
                :tabs="tabs"
                :serviceType="serviceType"
                @handleTabs="handleTabs"
                :dayLimit="dayLimit"
                :kiosk_id="kiosk_id"
                :mobileView="mobileView"
                :affiliateId="affiliateId"
                :updateOrderError="updateOrderError"
                :holidays="holidays"
                :timeslots="timeslots"
                :handleConfirmationPage="handleConfirmationPage"
            />
        </div>
    </div>
</template>
<script>
    import { useStore, mapGetters, mapActions } from 'vuex'
    import { ref, watch, computed } from 'vue'
    import { useRoute } from 'vue-router'
    import InputModal from '../pages/modals/InputModal.vue'
    import NotFoundModal from '../pages/modals/NotFound.vue'
    import SpinnerModal from '../pages/modals/Spinner.vue'
    import LoadingModal from '../pages/modals/LoadingModal.vue'
    import _ from "lodash"

    export default {
        components: {
            InputModal,
            NotFoundModal,
            SpinnerModal,
            LoadingModal,
        },
        setup() {
            const route = useRoute()
            const store = useStore()
            const selectionForm = computed(() => store.getters['checkout/form'])
            const tabs = computed(() => store.getters['checkout/tabs'])
            const datesArray = computed(() => store.getters['checkout/datesArray'])
            const pickupDatesArray = computed(() => store.getters['checkout/pickupDatesArray'])
            const calendarToggle = computed(() => store.getters['checkout/calendarToggle'])
            const cart = computed(() => store.getters['checkout/cart'])
            const productData = computed(() => store.getters['checkout/apiData'])
            const timeOfDayOptions = computed(() => store.getters['checkout/timeOfDayOptions'])
            const pickupTimeOfDayOptions = computed(() => store.getters['checkout/pickupTimeOfDayOptions'])
            const currentLocation = computed(() => store.getters['checkout/currentLocation'])
            const machineList = computed(() => store.getters['checkout/machineList'])
            const bundleList = computed(() => store.getters['checkout/bundleList'])
            const solutionList = computed(() => store.getters['checkout/solutionList'])
            const toolList = computed(() => store.getters['checkout/toolList'])
            const dataRes = computed(() => store.getters['checkout/dataRes'])
            const customer = computed(() => store.getters['checkout/customer'])
            const confirmation = computed(() => store.getters['checkout/confirmation'])
            const productPageErrorMsg = computed(() => store.getters['checkout/productPageErrorMsg'])
            const checkoutPageErrorMsg = computed(() => store.getters['checkout/checkoutPageErrorMsg'])
            const initialLoad = computed(() => store.getters['checkout/initialLoad'])
            const currentIndex = computed(() => store.getters['checkout/currentIndex'])
            const couponErrorMsg = computed(() => store.getters['checkout/couponErrorMsg'])
            const mobileView = window.innerWidth >= 640 ? ref(false) : ref(true)
            const initLoad = ref(false)
            const showInputModal = ref(false)
            const showNotFoundModal = ref(false)
            const showSpinner = ref(true)
            const datesLoaded = ref(false)
            const machinesLoaded = ref(false)
            const currentTabIndex = computed(() => store.getters['checkout/currentTabIndex'])
            const jdeNumber = computed(() => store.getters['checkout/jdeNumber'])
            const kiosk_id = computed(() => store.getters['checkout/kiosk_id'])
            const reservationsErrorMsg = computed(() => store.getters['checkout/reservationsErrorMsg'])
            const deliveryErrorMsg = computed(() => store.getters['checkout/deliveryErrorMsg'])
            const updateOrderError = computed(() => store.getters['checkout/updateOrderError'])
            const affiliateId = computed(() => store.getters['checkout/affiliateId'])

            const serviceType = (location.hostname.includes('rugdoctordelivery.com') || location.hostname.includes('delivery.rugdoctor.com') || location.hostname.includes('rugdoctorhomedelivery')) ? 'delivery' : 'reservation'
            const dayLimit = ref(serviceType === 'delivery' ? 14 : 7) //14 for Home Delivery, 7 for Reservation
            const firstSolution = ref({})
            const showLoadingModal = ref(false)
            const holidays = computed(() => store.getters['checkout/holidays'])
            const timeslots = computed(() => store.getters['checkout/timeslots'])
            return {
                route,
                store,
                selectionForm,
                tabs,
                datesArray,
                pickupDatesArray,
                calendarToggle,
                cart,
                productData,
                timeOfDayOptions,
                pickupTimeOfDayOptions,
                machineList,
                bundleList,
                solutionList,
                toolList,
                currentLocation,
                customer,
                confirmation,
                mobileView,
                dataRes,
                productPageErrorMsg,
                checkoutPageErrorMsg,
                initLoad,
                initialLoad,
                currentIndex,
                couponErrorMsg,
                showInputModal,
                showNotFoundModal,
                showSpinner,
                datesLoaded,
                machinesLoaded,
                currentTabIndex,
                serviceType,
                jdeNumber,
                dayLimit,
                kiosk_id,
                reservationsErrorMsg,
                deliveryErrorMsg,
                updateOrderError,
                affiliateId,
                firstSolution,
                showLoadingModal,
                holidays,
                timeslots,
            }
        },
        mounted() {
            window.addEventListener('resize', this.onResize)
            if (this.serviceType === 'delivery') {
                console.log('delivery')
                if (this.route.params.zipcode) {
                    this.showSpinner = true
                    this.$store.dispatch('checkout/setInitialLoad')
                    this.$store.dispatch('checkout/setZipcode', this.route.params.zipcode)
                    this.$router.push({ name: this.tabs[0].href })
                    this.$store.dispatch('checkout/setCurrentTabIndex', 0)

                }
                else if (this.currentLocation.zipcode) {
                    this.$store.dispatch('checkout/setInitialLoad')

                    if (this.cart.length === 0) {
                        this.$store.dispatch('checkout/resetForm')
                        this.$store.dispatch('checkout/resetDates')
                        // this.$store.dispatch('checkout/getData', this.currentLocation.zipcode)
                        this.$store.dispatch('checkout/getDates', this.currentLocation.zipcode)
                        //  this.$store.dispatch('checkout/getHolidays', this.currentLocation.zipcode)
                         this.$store.dispatch('checkout/getTimeSlots', this.currentLocation.zipcode)
                        this.$router.push({ name: this.tabs[0].href })
                        this.$store.dispatch('checkout/setCurrentTabIndex', 0)
                    }
                    else {
                        this.$router.push({ name: this.tabs[this.currentTabIndex || 0].href })
                        this.$store.dispatch('checkout/setCurrentTabIndex', this.currentTabIndex || 0)
                    }
                }
                else if (this.deliveryErrorMsg !== null) {
                    this.showInputModal = true
                    this.showSpinner = false
                }
                else if (this.currentLocation.zipcode === null) {
                    this.showInputModal = true
                    this.showSpinner = false
                }
            }
            else {
                console.log('Reservations')
                const titleName = document.querySelector('title')
                if (titleName) {
                    document.title = "Rug Doctor Reservations"
                }
                if (this.route.params.jdenumber) {
                    this.$store.dispatch('checkout/getReservationData', this.route.params.jdenumber)
                    this.$store.dispatch('checkout/setCurrentTabIndex', 0)
                    this.$router.push({ name: this.tabs[0].href })
                    this.$store.dispatch('checkout/setInitialLoad')
                }
                else if (this.jdeNumber) {
                    if (this.cart.length === 0) {
                        this.$store.dispatch('checkout/getReservationData', jdenumber)
                        this.$store.dispatch('checkout/resetForm')
                        this.$store.dispatch('checkout/resetDates')
                        this.$store.dispatch('checkout/setReservationWeek', this.dayLimit)
                        this.$router.push({ name: this.tabs[0].href })
                        this.$store.dispatch('checkout/setCurrentTabIndex', 0)
                    }
                    else {
                        this.$router.push({ name: this.tabs[this.currentTabIndex || 0].href })
                        this.$store.dispatch('checkout/setCurrentTabIndex', this.currentTabIndex || 0)
                    }
                }
                else if (this.reservationsErrorMsg !== null) {
                    this.showInputModal = true
                }
                else if (this.jdeNumber === null && this.machineList <= 0) {
                    this.showInputModal = true
                    this.showSpinner = false
                }

            }
            if (this.datesArray.length > 0) {
                this.showSpinner = false
            }
            // }
        },
        computed: {
            findSolution() {
                if (this.cart.length <= 0) {
                    this.firstSolution = {}
                    return false
                }
                else {
                    let firstSolution = this.cart.find(item => item.product_group === 'solution')
                    if (firstSolution) {
                        this.firstSolution = firstSolution
                        return true
                    }
                    this.firstSolution = {}
                    return false
                }
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize)
        },
        watch: {
            $route: function (newVal, oldVal) {

                if (newVal.query?.affiliate_id) {
                    if (typeof newVal.query?.affiliate_id === 'string') {
                        this.$store.dispatch('checkout/setAffiliate', parseInt(newVal.query.affiliate_id))
                    }
                    else if (typeof newVal.query?.affiliate_id === 'number') {
                        this.$store.dispatch('checkout/setAffiliate', newVal.query.affiliate_id)
                    }

                }
                if (this.serviceType === 'delivery') { //Home Delivery
                    if (newVal?.params?.zipcode && this.initialLoad === false) {
                        this.showInputModal = false
                        this.showSpinner = true
                        this.$store.dispatch('checkout/setInitialLoad')
                        this.$store.dispatch('checkout/setZipcode', newVal.params.zipcode)
                        this.$store.dispatch('checkout/resetForm')
                        this.$store.dispatch('checkout/resetDates')

                        // this.$store.dispatch('checkout/getData', this.route.params.zipcode)
                        this.$store.dispatch('checkout/getTimeSlots', this.route.params.zipcode)
                        this.$store.dispatch('checkout/getDates', this.route.params.zipcode)
                        // this.$store.dispatch('checkout/getHolidays', this.route.params.zipcode)
                        // this.$store.dispatch('checkout/getTimeSlots', this.route.params.zipcode)
                    }
                    else if (newVal.fullPath === "/") {
                        this.showSpinner = false
                        this.showInputModal = true
                        if (this.initialLoad === false) {
                            this.$store.dispatch('checkout/setInitialLoad')
                            this.$router.push({ name: this.tabs[0].href })
                            this.$store.dispatch('checkout/setCurrentTabIndex', 0)
                        }
                    }
                }
                else { // Reservations
                    if (newVal?.params?.jdeNumber && this.initialLoad === false) { //JDE number changes
                        this.showInputModal = false
                        this.$store.dispatch('checkout/setInitialLoad')
                        this.$store.dispatch('checkout/resetForm')
                        this.$store.dispatch('checkout/resetDates')

                        this.$store.dispatch('checkout/getReservationData', newVal.params.jdeNumber)
                        this.$store.dispatch('checkout/setReservationWeek', this.dayLimit)
                        this.$router.push({ name: this.tabs[0].href })
                        this.$store.dispatch('checkout/setCurrentTabIndex', 0)
                    }
                    else if (newVal.fullPath === "/") { //comes in with no params
                        this.showSpinner = false
                        this.showInputModal = true
                        if (this.initialLoad === false) {
                            this.$store.dispatch('checkout/setInitialLoad')
                            this.$router.push({ name: this.tabs[0].href })
                            this.$store.dispatch('checkout/setCurrentTabIndex', 0)
                        }
                    }

                    // else if (this.initialLoad === false) {
                    //     this.$store.dispatch('checkout/resetForm')
                    //     this.showSpinner = false
                    //     this.showNotFoundModal = true
                    // }
                }

            },
            productData: function (newVal, oldVal) {
                if (newVal !== null) {
                    if (newVal.length === 0 && this.serviceType === 'delivery') { //No Available Products

                        this.$store.dispatch('checkout/setZipcode', null)
                        this.$store.dispatch('checkout/resetForm')
                        // this.$router.push({ name: 'notfound' })
                        this.showNotFoundModal = true
                        this.showSpinner = false
                    }
                }
            },
            datesArray: function (newVal, oldVal) {
                if (!_.isEmpty(newVal)) {
                    if (this.serviceType === 'delivery') {
                        const anyAvailableDates = newVal.find(d => d.available === true)
                        // console.log(anyAvailableDates)
                        if (anyAvailableDates) {
                            this.datesLoaded = true
                            this.checkLoad()
                        }
                        // else {
                        //     this.showNotFoundModal = true
                        //     this.showSpinner = false
                        // }
                    }
                    else {
                        this.datesLoaded = true
                        this.checkLoad()
                    }

                }
                else {
                    // this.dataErrorHandle()
                }
            },
            machineList: function (newVal, oldVal) {
                if (!_.isEmpty(newVal)) {
                    this.machinesLoaded = true
                    this.checkLoad()
                }
                else {
                    // this.dataErrorHandle()
                }
            },
            deliveryErrorMsg: function (newVal, oldVal) {
                if (newVal) {
                    this.showSpinner = false
                    this.showNotFoundModal = true
                }
            },
            reservationsErrorMsg: function (newVal, oldVal) {
                if (newVal) {
                    this.showSpinner = false
                    this.showNotFoundModal = true
                }
            },
        },
        methods: {
            ...mapActions('checkout', [
                'setTabs', 'setApiData', 'getData', 'setWeek', 'resetDates', 'resetForm', 'setPickupWeek', 'getDates', 'setZipcode', 'setInitialLoad', 'setCurrentTabIndex', 'getReservationData', 'setReservationWeek', 'setAffiliate', 'getHolidays', 'getTimeSlots',
            ]),
            onResize() {
                if (window.innerWidth < 640) {
                    this.mobileView = true
                }
                else if (window.innerWidth >= 640) {
                    this.mobileView = false
                }
            },
            handleTabs(tabIndex, checkout) {
                if (checkout) {
                    const targetTab = this.tabs[tabIndex]
                    const targetTabIndex = this.tabs.indexOf(targetTab)

                    this.$store.dispatch('checkout/setCurrentTabIndex', targetTabIndex)
                    this.$router.push({ name: targetTab.href })
                }
                else if (this.tabValidation(location.hostname, tabIndex)) {
                    const targetTab = this.tabs[tabIndex]
                    const targetTabIndex = this.tabs.indexOf(targetTab)

                    this.$store.dispatch('checkout/setCurrentTabIndex', targetTabIndex)
                    this.$router.push({ name: targetTab.href })
                }
                else {
                    return
                }
            },
            nextTab() {
                const nextTabSelected = this.tabs[this.currentTabIndex + 1]
                if (nextTabSelected?.href) {
                    this.$store.dispatch('checkout/setCurrentTabIndex', (this.currentTabIndex + 1))
                    this.$router.push({ name: nextTabSelected.href })
                }
            },
            setCurrentTab(index) {
                this.$store.dispatch('checkout/setCurrentTabIndex', index)
            },
            tabValidation(hostname, tabIndex) { // checks if certain conditions are met that are dependent on the host.name
                if (this.serviceType === 'delivery') {
                    // Tab Validation
                    if (tabIndex === 0) { //Delivery
                        return true
                    }
                    else if (tabIndex === 1) { //Pickup
                        if (this.selectionForm.delivery.day && this.selectionForm.delivery.time) {
                            return true
                        }
                        else {
                            return false
                        }
                    }
                    else if (tabIndex === 2) { //Bundle
                        if (this.selectionForm.delivery.day && this.selectionForm.delivery.time && this.selectionForm.pickup.day && this.selectionForm.pickup.time) {
                            return true
                        }
                        else {
                            return false
                        }
                    }
                    else if (tabIndex === 3) { //Sellables
                        if (this.selectionForm.delivery.day && this.selectionForm.delivery.time && this.selectionForm.pickup.day && this.selectionForm.pickup.time && this.selectionForm.productSelected.machine) {
                            return true
                        }
                        else {
                            return false
                        }
                    }
                    else if (tabIndex === 4) { //Checkout
                        // const findSolution = this.cart.find(item => item.product_group === 'solution')
                        if (this.selectionForm.delivery.day && this.selectionForm.delivery.time && this.selectionForm.pickup.day && this.selectionForm.pickup.time && this.selectionForm.productSelected.machine) {
                            return true
                        }
                        else {
                            return false
                        }
                    }
                }
                else { //Reservations  else if(hostname.includes('reservation')){
                    // Tab Validation
                    if (tabIndex === 0) { //Reservation Date Selection
                        return true
                    }
                    else if (tabIndex === 1) { //Product
                        if (this.selectionForm.delivery.day) {
                            return true
                        }
                        else {
                            return false
                        }
                    }
                    else if (tabIndex === 2) { //Checkout
                        if (this.selectionForm.delivery.day && this.selectionForm.productSelected.machine) {
                            return true
                        }
                        else {
                            return false
                        }
                    }
                }
            },
            toggleInputModal() {
                this.showInputModal = !this.showInputModal
            },
            toggleNotFoundModal() {
                this.showNotFoundModal = !this.showNotFoundModal
                this.showInputModal = true
            },
            displaySpinner(show) {
                this.showSpinner = show
            },
            displayNotFoundModal() {
               this.showSpinner = false;
               this.showNotFoundModal = true;
            },
            changeZipcode(zipcode) {
                this.$store.dispatch('checkout/setZipcode', zipcode)
                // this.$store.dispatch('checkout/getData', zipcode)
                this.$store.dispatch('checkout/getTimeSlots', zipcode)
                this.$store.dispatch('checkout/getDates', zipcode)
                // this.$store.dispatch('checkout/getHolidays', zipcode)
                // this.$store.dispatch('checkout/getTimeSlots', zipcode)
                this.$router.push({ name: this.tabs[0].href })
                this.$store.dispatch('checkout/setCurrentTabIndex', 0)
            },
            changeJDE(jdenumber) {
                this.$store.dispatch('checkout/getReservationData', jdenumber)
                this.$store.dispatch('checkout/setReservationWeek', this.dayLimit)
                this.$router.push({ name: this.tabs[0].href })
                this.$store.dispatch('checkout/setCurrentTabIndex', 0)
            },

            checkLoad() { //checks if data has loaded
                if (this.datesLoaded === true && this.machinesLoaded === true) {
                    this.showSpinner = false
                    this.showInputModal = false
                    this.showNotFoundModal = false
                    return true
                }
                else {
                    this.showInputModal = false
                    this.showSpinner = true

                    return false
                }
            },
            handleConfirmationPage(){
                this.$router.push({ path: 'confirmation' })
            },
        }

    };
</script>
