import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'


const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage,
    modules: ['checkout'],
})

import * as checkout from './modules/Checkout'

const store = createStore({
    mutations: {},
    actions: {},
    modules: {
        checkout,
    },
    plugins: [ vuexLocal.plugin]
})

export default store
