
<template>
    <TransitionRoot
        as="template"
        :show="showSpinner"
    >
        <Dialog
            as="div"
            class="fixed z-50 inset-0 overflow-y-auto"
        >
            <div class="flex justify-center align-middle min-h-screen  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>
                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >&#8203;</span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div class="my-auto inline-block h-max max-w-5xl align-bottom  text-left  transform transition-all sm:align-middle px-2 pt-4 pb-8 ">
                        <div class=" m-auto">
                            <div class=" transform -translate-x-1/2 -translate-y-1/2 loader ease-linear rounded-full border-8 border-t-8 h-32 w-32 "></div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    export default {
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
        },

        props: ['showSpinner', 'timeOutFunc'],
        data() {
            return {
                interval: null,
                time: 0,
            }
        },
        mounted() {
            document.documentElement.style.padding = null
            document.documentElement.style.overflow = null
            this.interval = setInterval(() => {
                this.time++
                if(this.time === 40){
                    console.log('time exceeded')
                    this.handleTimeOut()
                    clearInterval(this.interval)
                }
            }, 1000)
        },
        beforeUnmount() {
            document.documentElement.style.padding = null
            document.documentElement.style.overflow = null
            clearInterval(this.interval)
        },
        methods: {
            handleTimeOut(){
                if(this.timeOutFunc){
                    this.timeOutFunc()
                }
            }
        }

    }
</script>
