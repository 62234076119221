
<template>
        <TransitionRoot
            as="template"
            :show="showNotFoundModal"
        >
            <Dialog
                as="div"
                class="fixed z-50 inset-0 overflow-y-auto"

            >
                <div class="flex items-end justify-center min-h-screen  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                    >
                        <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </TransitionChild>
                    <span
                        class="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >&#8203;</span>
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <div class="inline-block h-max max-w-6xl align-bottom  text-left  transform transition-all sm:align-middle" >
                        <div class="w-full h-full items-center font-thin font-open_sans text-lg xl:text-fs_headline_div bg-white  p-8 rounded-lg">
                            <div class="text lg:text-rd_fontsize-32  ">
                                <span v-if="serviceType ==='delivery'">Sorry, Home Delivery service is not supported in your area.</span>
                                <span v-else>{{reservationsErrorMsg || 'Sorry, that is not a valid JDE Number'}}</span>
                                <div class="my-4 flex flex-row gap-4">
                                        <div @click.prevent="toggleModal()"  class="flex justify-center content-center border-2 border-transparent bg-rd_red  w-full h-auto mx-auto md:w-[304px] outline-none ring-0 p-4 rounded-lg">
                                            <span v-if="serviceType ==='delivery'" class="text-white lg:text-rd_fontsize-22  text-center  font-semibold lg:py-4">Try another ZIP code</span>
                                            <span v-else class="text-white lg:text-rd_fontsize-22  text-center  font-semibold lg:py-4">Try another JDE Number</span>
                                        </div>
                                         <a
                                            href="https://www.rugdoctor.com/"
                                            class="flex justify-center content-center border-2 border-transparent bg-rd_red  w-full h-auto mx-auto md:w-[304px] outline-none ring-0 p-4 rounded-lg"
                                        >
                                            <span class="text-white lg:text-rd_fontsize-22  text-center  font-semibold py-4">Return</span>
                                        </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>
</template>

<script>
   import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    export default {
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
        },
        props: ['showNotFoundModal', "toggleNotFoundModal", 'serviceType', 'reservationsErrorMsg'],
        methods: {
            toggleModal(){
                this.toggleNotFoundModal()
            }
        },
        mounted(){
            document.documentElement.style.padding = null
            document.documentElement.style.overflow = null
        },
        beforeUnmount(){
            document.documentElement.style.padding = null
            document.documentElement.style.overflow = null
        }
    }
</script>
