export const formatRental = (cartItems, machineList, toolList, solutionList) => {
        const productList = machineList.concat(toolList, solutionList)
        const results = []
        const bundles = []
        const items = JSON.parse(JSON.stringify(cartItems))
        let skuNumbers = []

        for (let i = 0; i < items.length; i++) {
            let product = {
                product_type_id: items[i].id,
                quantity: items[i].quantity ? items[i].quantity : 1
            }
            if (/bundle/gi.test(items[i].name)) {
                bundles.push(items[i])
            }
            else {
                results.push(product)
            }
        }
        bundles.forEach(bundle => {
            let description = bundle.description_3 ?? ''
            let findSKUNumbers = description.match(/\d+/g) ?? []
            skuNumbers = skuNumbers.concat(findSKUNumbers)
        })
        for (let l = 0; l < skuNumbers.length; l++) {
            let findItem = productList.find(element => element.sku_number === skuNumbers[l]);
            let targetId = findItem?.id
            let existing = results.find(item => item?.product_type_id === targetId);

            if (findItem) {
                if (existing) {
                    existing.quantity = ((typeof existing.quantity === 'number') ? existing.quantity + 1 : 1)
                }
                else {
                    if(targetId){
                        results.push({
                            product_type_id: targetId,
                            quantity: findItem.quantity ? findItem.quantity : 1
                        })
                    }

                }
            }
        }
        return results
};

export const findRelatedMachine = (bundle, machineList) => {
    const productList = JSON.parse(JSON.stringify(machineList))
    let skuNumbers = []
    let description = bundle.description_3 ?? ''
    let findSKUNumbers = description.match(/\d+/g) ?? []
    skuNumbers = skuNumbers.concat(findSKUNumbers)

    for (let l = 0; l < skuNumbers.length; l++) {
        let findItem = productList.find(element => element.sku_number === skuNumbers[l] && element.product_group === 'machine');
        if(findItem){
            return findItem.related_products || []
        }
    }
    return []
};


export const formatBundlePrice = (dates) => {

}
