// import auth from '../../middleware/auth'
export const generateRoutes = () => {
    if (location.hostname.includes('rugdoctordelivery.com') || location.hostname.includes('delivery.rugdoctor.com') || location.hostname.includes('rugdoctorhomedelivery')) {
        return [
            {
                path: '/delivery',
                name: 'delivery',
                // meta: {
                //     middleware: [auth],
                //     breadCrumb: "Dashboard"
                // },
                props: true,
                component: () => import('../../pages/Delivery.vue'),
            },
            {
                path: '/products',
                name: 'products',
                // meta: {
                //     middleware: [auth],
                //     breadCrumb: "Dashboard"
                // },
                component: () => import('../../pages/Products.vue'),
            },
            {
                path: '/pickup',
                name: 'pickup',
                // meta: {
                //     middleware: [auth],
                //     breadCrumb: "Dashboard"
                // },
                component: () => import('../../pages/Pickup.vue'),
            },
            {
                path: '/checkout',
                name: 'checkout',
                // meta: {
                //     middleware: [auth],
                //     breadCrumb: "Dashboard"
                // },
                component: () => import('../../pages/Checkout.vue'),
            },
            {
                path: '/confirmation',
                name: 'confirmation',
                // meta: {
                //     middleware: [auth],
                //     breadCrumb: "Dashboard"
                // },
                component: () => import('../../pages/Confirmation.vue'),
            },
            // {
            //     path: '/notfound',
            //     name: 'notfound',
            //     // meta: {
            //     //     middleware: [auth],
            //     //     breadCrumb: "Dashboard"
            //     // },
            //     props: true,
            //     component: () => import('../../pages/NotFound.vue'), //change
            // },
            {
                path: '/locator/:zipcode',
                name: 'Start',
                // meta: {
                //     middleware: [auth],
                //     breadCrumb: "Dashboard"
                // },
                props: true,
                component: () => import('../../components/App.vue'),
            },
            {
                path: '/bundles',
                name: 'bundles',
                // meta: {
                //     middleware: [auth],
                //     breadCrumb: "Dashboard"
                // },
                props: true,
                component: () => import('../../pages/Bundles.vue'),
            },
            {
                path: '/sellables',
                name: 'sellables',
                // meta: {
                //     middleware: [auth],
                //     breadCrumb: "Dashboard"
                // },
                props: true,
                component: () => import('../../pages/Sellables.vue'),
            },
        ]
    }
    else { // Reservations
        return [
            {
                path: '/dates',
                name: 'dates',
                // meta: {
                //     middleware: [auth],
                //     breadCrumb: "Dashboard"
                // },
                props: true,
                component: () => import('../../pages/Delivery.vue'),
            },
            {
                path: '/products',
                name: 'products',
                // meta: {
                //     middleware: [auth],
                //     breadCrumb: "Dashboard"
                // },
                component: () => import('../../pages/Products.vue'),
            },
            {
                path: '/pickup',
                name: 'pickup',
                // meta: {
                //     middleware: [auth],
                //     breadCrumb: "Dashboard"
                // },
                component: () => import('../../pages/Pickup.vue'),
            },
            {
                path: '/checkout',
                name: 'checkout',
                // meta: {
                //     middleware: [auth],
                //     breadCrumb: "Dashboard"
                // },
                component: () => import('../../pages/Checkout.vue'),
            },
            {
                path: '/confirmation',
                name: 'confirmation',
                // meta: {
                //     middleware: [auth],
                //     breadCrumb: "Dashboard"
                // },
                component: () => import('../../pages/Confirmation.vue'),
            },
            {
                path: '/:jdeNumber',
                name: 'Start',
                // meta: {
                //     middleware: [auth],
                //     breadCrumb: "Dashboard"
                // },
                props: true,
                component: () => import('../../components/App.vue'),
            },
        ]
    }

}
